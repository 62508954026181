






















































































































































































































































































































import { defineComponent, ref, computed, Ref } from '@vue/composition-api';
import core from '@/admin/core';
import { useGifts, getExpirationTypeText, getExpirationPeriodIntervalText } from '@/admin/gift';
import { addGift, GiftHistoryProps } from '@/admin/history';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import { downloadCsv } from '@/admin/util';
import FcNumberForm from '@/components/FcNumberForm.vue';
import { useUser } from '@/admin/user';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import { DisplayDate } from '@/admin/util';

export default defineComponent({
  name: 'Gifts',
  components: {
    FcNumberForm,
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('gifts'));

    const isPermitted = core.isPermitted; // ユーザー情報取得用 IP制限の確認
    const { gifts } = useGifts();
    const { getAllUsers } = useUser();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const headers = [
      { text: 'ステータス', value: 'isArchive' },
      { text: '作成日', value: 'createDate.date' },
      { text: 'ID/タイトル', value: 'title', width: '300px' },
      { text: '有効期限', value: 'expiration' },
      { text: 'もぎり機能', value: 'isUsable' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];

    // フリーワード検索
    const searchWord = ref('');
    const isDisplayItem = ref(false);
    const displayGifts = computed(() => {
      const filterGift = gifts.items
        // 削除スイッチによる絞り込み
        .filter((item) => (!isDisplayItem.value ? !item.isArchive : item))
        // フリーワードによる絞り込み
        .filter((item) => !searchWord.value || JSON.stringify(item).includes(searchWord.value));
      return filterGift;
    });

    const deleteGift = async (giftId: string) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      try {
        await gifts.deleteGift(giftId);
        gifts.items = gifts.items.filter((item) => item.giftId !== giftId);
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
    };

    const active = ref(false);
    const isCheckUsers = ref(false);
    const userIds: Ref<string[]> = ref([]);
    const giftId = ref('');
    const open = (id: string) => {
      giftId.value = id;
      active.value = true;
    };
    // NOTE: ユーザーIDが重複したデータをインポートされる可能性があるので、別途一意となるitemIdを設定する
    const items: Ref<{
      itemId: string;
      userId: string;
      quantity: number;
      status: string;
    }[]> = ref([]);
    const isErrorUser = computed(() => items.value.some((item) => item.status === 'unchecked' || item.status === 'invalid' || item.status === 'duplicate'));
    const dialogTableHeaders = [
      { text: 'userId', value: 'userId' },
      { text: 'quantity', value: 'quantity' },
      { text: '', value: 'actions', align: 'end' }
    ];
    const userCsv: Ref<Blob | null> = ref(null);
    const selectedUsers: Ref<{
      itemId: string;
      userId: string;
      quantity: number;
      status: string;
    }[]> = ref([]);
    const isErrorUserIds = computed(() => [
      ...new Set(items.value.filter((item) => {
        return item.status === 'invalid' || item.status === 'duplicate'
      }).map((item) => item.userId))
    ]);
    const csvUserIds = computed(() => items.value.map((item) => item.userId));
    const hasInvalidUser = computed(() => items.value.some((item) => item.status === 'invalid'));
    const duplicatedUsers = computed(() => {
      const users = new Set();
      const duplicate = new Set();
      csvUserIds.value.forEach((item) => {
        if (users.has(item)) duplicate.add(item);
        else users.add(item);
      });
      return Array.from(duplicate) as string[]; 
    });
    const deleteSelectedUsers = () => {
      selectedUsers.value.forEach((item) => {
        const index = items.value.indexOf(item);
        items.value.splice(index, 1);
        if (!duplicatedUsers.value.includes(item.userId) && item.status === 'duplicate') {
          const duplicatedItem = items.value.find((duplicated) => duplicated.userId === item.userId);
          duplicatedItem && items.value.splice(items.value.indexOf(duplicatedItem), 1, {...item, status: 'valid'});
        }
      });
      selectedUsers.value = [];
    };
    const upload = () => {
      if (!userCsv.value) return alert('ファイルを選択してください。');
      const reader = new FileReader();
      reader.readAsText(userCsv.value);
      reader.onload = () => {
        if (!reader.result) return;
        const csv = reader.result.toString().replace(/\r\n/g, '\n');
        const rows = csv.split('\n');
        const headerRow = rows.shift() || '';
        const headerColumns = headerRow.split(',');
        if (!headerColumns.includes('userId') || !headerColumns.includes('quantity')) {
          // userIdとquantityのカラムがあればOKとする
          alert('CSVファイルのフォーマットが間違っています。確認してください。');
          return;
        }
        if (rows.length === 0) {
          alert('インポートされたユーザーデータがありません。CSVファイルを確認してください。');
          return;
        }
        selectedUsers.value = [];
        items.value =
          rows.map((row) => {
            const data = { itemId: '', userId: '', quantity: 0, status: '' };
            const columns = row.split(',');
            for (let i = 0; i < columns.length; i++) {
              data.itemId = Math.random().toString(36).slice(-8);
              // userIdとquantityの順番が違うのや、違うカラムがあるのは許容して、よしなに格納する
              data.userId = columns[headerColumns.indexOf('userId')];
              data.quantity = parseInt(columns[headerColumns.indexOf('quantity')]);
              data.status = 'unchecked';
            }
            return data;
          });
      };
    };

    // ダウンロードボタン
    const download = () => {
      // actionsの列は削除
      const csvHeaders = [
        { text: 'userId', value: 'userId' },
        { text: 'quantity', value: 'quantity' },
      ];
      downloadCsv(csvHeaders, [], 'gift-users.csv');
    };

    const close = async () => {
      active.value = false;
      userCsv.value = null;
      items.value = [];
      selectedUsers.value = [];
    };

    const add = async () => {
      if (!(await confirmDialog('本当に特典を追加しますか？'))) return;
      for (let index = 0; index < items.value.length; index++) {
        const item = items.value[index];
        if (!item.quantity) {
          alert(`${item.userId}の数量を確認してください。`);
          return;
        }
      }
      const props: GiftHistoryProps = {
        giftId: giftId.value,
        targets: [],
      };
      items.value.forEach((item) => {
        props.targets.push({ endUsername: item.userId, quantity: item.quantity });
      });
      try {
        await addGift(props);
        notification.notify('追加処理が完了しました。');
        // 処理が失敗した場合は、修正してそのまま再度追加処理を行えるようにモーダルは閉じない
        close();
      } catch (error) {
        notification.error(error);
      }
    };

    const deleteUpdateGift = async (item: any) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      const index = items.value.indexOf(item);
      items.value.splice(index, 1);
      if (!duplicatedUsers.value.includes(item.userId) && item.status === 'duplicate') {
        const duplicatedItem = items.value.find((duplicated) => duplicated.userId === item.userId);
        duplicatedItem && items.value.splice(items.value.indexOf(duplicatedItem), 1, {...item, status: 'valid'});
      }
      if (selectedUsers.value.some((selectedItem) => selectedItem.userId === item.userId)) {
        selectedUsers.value.splice(selectedUsers.value.indexOf(item), 1);
      }
    };

    const checkUserIds = async () => {
      try {
        isCheckUsers.value = true;
        if (isPermitted && !userIds.value.length) {
          await getAllUsers().then((_users) => {
            userIds.value = _users.filter((user) => !user.deleteDate).map((user) => user.userId);
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        // ユーザーデータを取得、有効なデータが取得できない場合
        if (!userIds.value.length) {
          isCheckUsers.value = false;
        } else {
          // ユーザーデータのチェック
          items.value.forEach((user) => {
            if (!userIds.value.includes(user.userId)) {
              user.status = 'invalid';
            } else if (duplicatedUsers.value.includes(user.userId)) {
              user.status = 'duplicate';
            } else {
              user.status = 'valid';
            }
          });
          isCheckUsers.value = false;

          // エラー（退会済みまたは存在しないユーザーID指定）があればアラートを表示する
          if (hasInvalidUser.value) {
            alert(
              '通知対象外のユーザーが含まれています。\nアラートアイコンが表示されている行について、退会済みまたは存在しないユーザーIDを指定していないかご確認ください。'
            );
          } else if (duplicatedUsers.value.length !== 0) {
            // 重複しているユーザーがいる場合もアラートを表示する
            alert(
              '重複しているユーザーが含まれています。\nアラートアイコンが表示されている行について、重複しているユーザーIDを指定していないかご確認ください。'
            );
          }
        }
      }
    };

    return {
      DisplayDate,
      pageTitle: '特典',
      myRoleSettings,
      gifts,
      headers,
      deleteGift,
      searchWord,
      isDisplayItem,
      displayGifts,
      upload,
      close,
      items,
      dialogTableHeaders,
      isErrorUserIds,
      userCsv,
      selectedUsers,
      active,
      open,
      giftId,
      add,
      deleteUpdateGift,
      download,
      checkUserIds,
      isCheckUsers,
      isErrorUser,
      isPermitted,
      getExpirationTypeText,
      getExpirationPeriodIntervalText,
      deleteSelectedUsers,
    };
  },
});
